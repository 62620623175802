import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import ImageContext from '../contexts/ImageContext';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import PromptContext from '../contexts/PromptContext';
import ItemsContext from '../contexts/ItemsContext';
import { FloppyDisk, X } from '@phosphor-icons/react';
import Cookies from 'js-cookie';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import '../css/fonts.css';
import BouncyLoader from '../components/BouncyLoader';

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

const Background = styled.div`
  width: 100%;
  background: #fff9ef;
`;

const Container = styled.div`
  padding-top: 2.5rem;
  width: 62%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
      width: 85%;
  }
  padding-bottom: 10rem;
`;

const Step1 = styled.div`
  margin-bottom: 4rem;
`;

const Step2 = styled.div`
  
`;

const Label = styled.div`
  font-family: 'NewKansas';
  font-size: 32px;
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
  color: #222222;
`;

const Paragraph = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #222222;
`

const LabelNoMargin = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: .7rem;
`

const Label2 = styled.label`
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;


const Button = styled.button`
  width: 230px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
    transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  align-items: center
  justify-content: center;
  gap: 2rem;
`;

const GenerateMoreButton = styled.button`
  width: 250px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#222' : '#888')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#222' : '#888')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    width: 230px;
  }
`

const SaveButton = styled.button`
  width: 250px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    width: 230px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 21px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const ImWorkingHereButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 200px;
  height: 50px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(80deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  border: none;
  border-radius: 30px;
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 0;
`;

const TextArea = styled.textarea`
  resize: none;
  border: 4px solid #d5d4c3;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 100px;
  color: #222222;
  max-height: 500px;
  padding: 8px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  z-index: 3;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  margin-bottom: 1.5rem;
`;

const MinimizedTextArea = styled.textarea`
  resize: none;
  border: 4px solid #d5d4c3;
  border-radius: 15px;
  width: 100%;
  color: #222222;
  padding: 8px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #d5d4c3;
  z-index: 3;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  margin-bottom: .5rem;
`;

const MinimizedLabel = styled.div`
  font-family: 'NewKansas';
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
  color: #222222;
`;

// variants I've looked at for edit button color: #222222, #D8315B, #F71735
const EditButton = styled.button`
  width: 180px;
  border: none;
  height: 52px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #F71735;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 78% / 5%;
    background-color: #F71735;
    top: -1px;
    bottom: -1px;
    right: 18px;
    left: 18px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const EditButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  padding-left: 10px;
  font-size: 21px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: linear-gradient(80deg, #c13584, #e1306c, #fd1d1d);
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #333;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 10px;
  margin-bottom: 1.5rem;
`;

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 4px solid ${({selected}) => (selected ? '#727fc0' : '#d5d4c3 ')};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.03);
  }
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const GridImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const LoginOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const LoginContainer = styled(motion.div)`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
`;

const LoginTitle = styled.h2`
  font-family: 'NewKansas', sans-serif;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const LoginButton = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  background: #d5d4c3;
  color: #222222;
  border: none;
  border-radius: 18px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #d5d4c3;
  }
  transition: background-color 0.2s;  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
`;

const SVGContainer = styled.div`
  height: 1.5em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    height: 100%;
    width: auto;
  }
`;

const IconHeaderContainer = styled.div`
  border: none;
  border-radius: 50%;
  background: #fcc7c1;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

const BouncyLoaderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;


const Customize = () => {
    const { imageUrl, setImageUrl } = useContext(ImageContext);
    const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
    const { prompt, setPrompt } = useContext(PromptContext);
    const { items, setItems } = useContext(ItemsContext);
    const [previousPrompt, setPreviousPrompt] = useState('');
    const [imWorkingHere, setImWorkingHere] = useState(false);
    const imWorkingHereRef = useRef(imWorkingHere);
    const eventSourceRef = useRef(null);
    const navigate = useNavigate();
    const [animate, setAnimate] = useState(false);
    const [showStep2, setShowStep2] = useState(false);
    const [newDoodlebugs, setNewDoodlebugs] = useState([]);
    const [selectedDoodlebugs, setSelectedDoodlebugs] = useState([]);
    const [minimizeStep1, setMinimizeStep1] = useState(false);
    const [testImages, setSetImages] = useState(['https://aiapparel-generations.s3.us-west-2.amazonaws.com/kawaii_cat_4.png', 'https://aiapparel-generations.s3.us-west-2.amazonaws.com/kawaii_chef_6.png', 'https://aiapparel-generations.s3.us-west-2.amazonaws.com/kawaii_something_haha.png', 'https://aiapparel-generations.s3.us-west-2.amazonaws.com/kawaii_bear_2.png']);

    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [containerHeight, setContainerHeight] = useState('auto');
    const minimizedRef = useRef(null);
    const expandedRef = useRef(null);

    useEffect(() => {
      document.title = 'Doodlebug | Create Doodlebug';
    }, []);

    useEffect(() => {
      if (minimizeStep1 && minimizedRef.current) {
        setContainerHeight(minimizedRef.current.offsetHeight);
      } else if (expandedRef.current) {
        setContainerHeight(expandedRef.current.offsetHeight);
      }
    }, [minimizeStep1]);

    useEffect(() => {
      if (containerHeight === 'auto') {
        if (minimizeStep1 && minimizedRef.current) {
          setContainerHeight(minimizedRef.current.offsetHeight);
        } else if (expandedRef.current) {
          setContainerHeight(expandedRef.current.offsetHeight);
        }
      }
    }, [prompt]);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // updates the imWorkingHereRef when imWorkingHere changes
    useEffect(() => {
      imWorkingHereRef.current = imWorkingHere;
    }, [imWorkingHere]);

    //this will close the generateImage server connection if the component unmounts
    useEffect(() => {
      return () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
        }
      };
    }, []);

    // updates prompt as user types
    const updatePrompt = (event) => {
      setPrompt(event.target.value);
    };

    // gets new doodlebugs
    const generateImage = async () => {
      if(prompt !== previousPrompt) {
        setNewDoodlebugs(prevDoodlebugs => prevDoodlebugs.filter(url => selectedDoodlebugs.includes(url)));
      }
      setPreviousPrompt(prompt);
      setImWorkingHere(true);
      setMinimizeStep1(true);
      setShowStep2(true);
      const generateUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/generateonreplicate?prompt=' : 'http://localhost:3001/api/generateonreplicate?prompt=';
      const eventSource = new EventSource(generateUrl + encodeURIComponent(prompt.substring(0,1947)));
      eventSourceRef.current = eventSource;

      eventSource.addEventListener('success', (event) => {
        try {
            const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
            const imageUrls = JSON.parse(event.data);
            Cookies.set('imageUrls', event.data, { expires: 7 , domain: cookieDomain });
            // Add new doodlebugs to existing ones
            setNewDoodlebugs(prevDoodlebugs => [...prevDoodlebugs, ...imageUrls]);
            setImWorkingHere(false);
        } catch (error) {
            console.log(error);
        }
        eventSource.close();
      });

      eventSource.addEventListener('failure', (event) => {
          setImWorkingHere(false);
          setShowErrorPopup(true);
          eventSource.close();
          setTimeout(() => {
              setShowErrorPopup(false);
          }, 3000);
      });
    };

    // return to editing step 1 inputs (prompt)
    const goBackToStep1 = () => {
      setMinimizeStep1(false);
    };

    const openLogin = () => {
      setShowLoginPopup(true);
    };

    const closeLogin = () => {
      setShowLoginPopup(false);
    };

    // save the doodlebugs to our saved doodlebugs array, or prompt login if we are not logged in already
    const saveDoodlebugs = async () => {
      // add to savedDoodlebugs optimistically, to make sure that it's done before we redirect.
      const originalSavedDoodlebugs = savedDoodlebugs.slice();
      let newSavedDoodlebugs = savedDoodlebugs.slice();
      for(let i = 0; i < selectedDoodlebugs.length; i++) {
        if (!newSavedDoodlebugs.includes(selectedDoodlebugs[i])) {
          newSavedDoodlebugs.push(selectedDoodlebugs[i]);
        }
      }
      setSavedDoodlebugs(newSavedDoodlebugs);

      try {
        const saveDoodlebugsUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/savedoodlebugs' : 'http://localhost:3001/api/savedoodlebugs';
        const response = await fetch(saveDoodlebugsUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'CSRF-Token': window.csrfToken
          },
          body: JSON.stringify({ doodlebugs: selectedDoodlebugs }),
          credentials: 'include'
        });
        const data = await response.json();

        if (response.ok) {
          navigate('/order/phone-case');
        } else if (data.triggerLogin) {
          setSavedDoodlebugs(originalSavedDoodlebugs);
          openLogin();
        } else {
          setSavedDoodlebugs(originalSavedDoodlebugs);
          throw new Error(data.message || 'Error saving doodlebugs');
        }
      } catch (error) {
        console.error('Save failed', error);
        setSavedDoodlebugs(originalSavedDoodlebugs);
      }
    };

    // to login with google
    // we include in this request the destination and the doodlebugs to save, so that the login logic also handles the doodlebug saving.
    const googleLogin = async () => {
      const loginUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/prepare-google-auth' : 'http://localhost:3001/prepare-google-auth';
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:  JSON.stringify({ destination: 'order/phone-case', urls: selectedDoodlebugs, items: items }),
        credentials: 'include'
      });
      const data = await response.json();
      window.location.href = data.authUrl;
    };

    // select doodlebugs from newdoodlebugs, for later saving
    function selectDoodlebug(url) {
      if (!selectedDoodlebugs.includes(url)) {
          const newSelectedDoodlebugs = selectedDoodlebugs.slice();
          newSelectedDoodlebugs.push(url);
          setSelectedDoodlebugs(newSelectedDoodlebugs);
      } else {
          const newSelectedDoodlebugs = selectedDoodlebugs.filter(value => value !== url);
          setSelectedDoodlebugs(newSelectedDoodlebugs);
      }
    };

    return (
          <Background>
            {showErrorPopup && (
              <ErrorPopup><PopupText>Generation failed. Our servers are experiencing high demand--please try again.</PopupText></ErrorPopup>
            )}
            <Container>
              <LayoutGroup>
                <motion.div layout>
                <AnimatePresence mode="wait">
                  
                    {minimizeStep1 ? (
                      <motion.div
                        key="step_1_minimized"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                      >
                        <Step1>
                          <MinimizedLabel>Describe your doodlebug</MinimizedLabel>
                          <MinimizedTextArea value={prompt} onChange={updatePrompt} readOnly></MinimizedTextArea>
                          <EditButton disabled={prompt.trim() === ''} valid={prompt.trim() !== ''} onClick={goBackToStep1}><EditButtonText>Edit</EditButtonText></EditButton>
                        </Step1>
                      </motion.div>
                    ): (
                      <motion.div
                        key="step_1_expanded"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                      >
                        <Step1>
                          <Label>Describe your doodlebug</Label>
                          <Paragraph>Type whatever you like below. Your doodlebug can be anything—a cat, a mouse, a chef, a dishwasher, a bag of chips, the moon. Or, use the suggestions below to help you come up with something you like!</Paragraph>
                          <TextArea value={prompt} onChange={updatePrompt} placeholder="this is placeholder text--come up with something later for this"></TextArea>
                          <Button disabled={prompt.trim() === ''} valid={prompt.trim() !== ''} onClick={generateImage}><ButtonText>Create Doodlebug</ButtonText></Button>
                        </Step1>
                      </motion.div>
                    )}
                </AnimatePresence>
                </motion.div>
              <motion.div
                key="step_2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                layout
              >
                {showStep2 && (
                  <motion.div layout>
                    <Step2>
                      <Label>Keep your favorites</Label>
                      <Paragraph>Press or click on a doodlebug to select it. You can keep as many as you like.</Paragraph>
                      <StyledGrid>
                        {newDoodlebugs.map((url) => (
                          <motion.div layout>
                            <ImageContainer key={url} onClick={() => selectDoodlebug(url)} selected={selectedDoodlebugs.includes(url)}>
                              <GridImage src={url} alt="Doodlebug" />
                            </ImageContainer>
                          </motion.div>
                        ))}
                      </StyledGrid>
                      {imWorkingHere && (
                        <BouncyLoaderContainer>
                          <BouncyLoader />
                        </BouncyLoaderContainer>
                      )}
                      <BottomButtonsContainer>
                        <GenerateMoreButton disabled={imWorkingHere || newDoodlebugs.length < 1} valid={!imWorkingHere && !newDoodlebugs.length < 1} onClick={generateImage}><ButtonText>Create More</ButtonText></GenerateMoreButton>
                        <SaveButton disabled={selectedDoodlebugs.length < 1} valid={selectedDoodlebugs.length > 0} onClick={saveDoodlebugs}><ButtonText>Save {selectedDoodlebugs.length} Doodlebug{selectedDoodlebugs.length === 1 ? '' : 's'}</ButtonText></SaveButton>
                      </BottomButtonsContainer>
                    </Step2>
                  </motion.div>
                )}
              </motion.div>
              </LayoutGroup>
            </Container>
            <AnimatePresence>
              {showLoginPopup && (
                <LoginOverlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={closeLogin}
                >
                  <LoginContainer
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconHeaderContainer>
                        <FloppyDisk size={36} color="#222222" />
                    </IconHeaderContainer>
                    <LoginTitle>Connect an account to save your doodlebugs!</LoginTitle>
                    <LoginButton onClick={googleLogin}>
                        <SVGContainer>
                            <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-1693 -927)">
                                    <circle transform="translate(1694 928)" cx="16" cy="16" r="16" fill="#fff"></circle>
                                    <path d="M1717.68 944.182a9.192 9.192 0 0 0-.145-1.636H1710v3.095h4.305a3.68 3.68 0 0 1-1.6 2.415v2.006h2.585a7.8 7.8 0 0 0 2.39-5.88z" fill="#4285f4" fillRule="evenodd"></path>
                                    <path d="M1710 952a7.638 7.638 0 0 0 5.294-1.938l-2.585-2.007a4.826 4.826 0 0 1-7.185-2.535h-2.673v2.073A8 8 0 0 0 1710 952z" fill="#34a853" fillRule="evenodd"></path>
                                    <path d="M1705.5 945.52a4.73 4.73 0 0 1 0-3.04v-2.073h-2.673a8.01 8.01 0 0 0 0 7.185l2.673-2.073z" fill="#fbbc05" fillRule="evenodd"></path>
                                    <path d="M1710 939.182a4.322 4.322 0 0 1 3.058 1.2l2.295-2.295A7.688 7.688 0 0 0 1710 936a8 8 0 0 0-7.149 4.407l2.673 2.073a4.768 4.768 0 0 1 4.476-3.3z" fill="#ea4335" fillRule="evenodd"></path>
                                </g>
                            </svg>
                        </SVGContainer>
                        Sign in with Google
                    </LoginButton>
                    <CloseButton onClick={closeLogin}>
                        <X size={24} color="#222222"/>
                    </CloseButton>
                  </LoginContainer>
                </LoginOverlay>
              )}
            </AnimatePresence>
          </Background>
    );
};

export default Customize;
