import React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const Container = styled.div`
    height: 60px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 600px) {
        width: 85%;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    transition: top 0.2s ease-in-out;
    z-index: 500;
`;

const Left = styled.div`
    flex: 1;
    text-align: left;
    font-family: 'Righteous';
    font-size: 2rem;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 1.3rem;
    }
`;

const HomeText = styled.span`
    font-family: 'Righteous';
    font-size: 2rem;
    color: black;
    &:hover {
        transform: scale(1.1);
        transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.95);
        transition: all 0.05s ease-in-out;
    }
    @media (max-width: 600px) {
        font-size: 1.3rem;
    }
`

const Center = styled.div`
    flex: 1;
    text-align: center;
`;

const Right = styled.div`
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`

const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
`

const CleanNavbar = ({handleHomeClick}) => {

    return (
        <Container>
            <Wrapper>
                <Left>
                    <StyledLink to="/" onClick={handleHomeClick}>
                        <Button>
                            <HomeText>Doodlebug</HomeText>
                        </Button>
                    </StyledLink>
                </Left>
                <Center></Center>
                <Right></Right>
            </Wrapper>
        </Container>
    )
}

export default CleanNavbar;