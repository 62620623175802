import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BouncyLoader from '../components/BouncyLoader';
import useImage from 'use-image';
import Select from 'react-select';
import { Image, Layer, Rect, Stage, Transformer } from 'react-konva';
import styled, { css, keyframes } from 'styled-components';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { ArrowCounterClockwise, ArrowsOutCardinal, Check, CheckCircle, Plus, ShoppingCartSimple, Star, Timer, Truck } from '@phosphor-icons/react';
import Rating from 'react-rating';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import iphone7 from '../img/mockups/iphone_7.webp';
import iphone7Plus from '../img/mockups/iphone_7_plus.webp';
import iphone8 from '../img/mockups/iphone_8.webp';
import iphone8Plus from '../img/mockups/iphone_8_plus.webp';
import iphoneX from '../img/mockups/iphone_x.webp';
import iphoneXR from '../img/mockups/iphone_xr.webp';
import iphoneXS from '../img/mockups/iphone_xs.webp';
import iphoneXSMax from '../img/mockups/iphone_xs_max.webp';
import iphone11 from '../img/mockups/iphone_11.webp';
import iphone11Pro from '../img/mockups/iphone_11_pro.webp';
import iphone11ProMax from '../img/mockups/iphone_11_pro_max.webp';
import iphone12Mini from '../img/mockups/iphone_12_mini.webp';
import iphone12 from '../img/mockups/iphone_12.webp';
import iphone12Pro from '../img/mockups/iphone_12_pro.webp';
import iphone12ProMax from '../img/mockups/iphone_12_pro_max.webp';
import iphone13Mini from '../img/mockups/iphone_13_mini.webp';
import iphone13 from '../img/mockups/iphone_13.webp';
import iphone13Pro from '../img/mockups/iphone_13_pro.webp';
import iphone13ProMax from '../img/mockups/iphone_13_pro_max.webp';
import iphone14 from '../img/mockups/iphone_14.webp';
import iphone14Plus from '../img/mockups/iphone_14_plus.webp';
import iphone14Pro from '../img/mockups/iphone_14_pro.webp';
import iphone14ProMax from '../img/mockups/iphone_14_pro_max.webp';
import iphone15 from '../img/mockups/iphone_15.webp';
import iphone15Plus from '../img/mockups/iphone_15_plus.webp';
import iphone15Pro from '../img/mockups/iphone_15_pro.webp';
import iphone15ProMax from '../img/mockups/iphone_15_pro_max.webp';
import galaxyS10 from '../img/mockups/galaxy_s10.webp';
import galaxyS10E from '../img/mockups/galaxy_s10_e.webp';
import galaxyS10Plus from '../img/mockups/galaxy_s10_plus.webp';
import galaxyS20 from '../img/mockups/galaxy_s20.webp';
import galaxyS20Plus from '../img/mockups/galaxy_s20_plus.webp';
import galaxyS20Ultra from '../img/mockups/galaxy_s20_ultra.webp';
import galaxyS21 from '../img/mockups/galaxy_s21.webp';
import galaxyS21Plus from '../img/mockups/galaxy_s21_plus.webp';
import galaxyS21Ultra from '../img/mockups/galaxy_s21_ultra.webp';
import galaxyS22 from '../img/mockups/galaxy_s22.webp';
import galaxyS22Plus from '../img/mockups/galaxy_s22_plus.webp';
import galaxyS22Ultra from '../img/mockups/galaxy_s22_ultra.webp';
import galaxyS23 from '../img/mockups/galaxy_s23.webp';
import galaxyS23Plus from '../img/mockups/galaxy_s23_plus.webp';
import galaxyS23Ultra from '../img/mockups/galaxy_s23_ultra.webp';
import iphone7_small from '../img/mockups_small/iphone_7_small.webp';
import iphone7Plus_small from '../img/mockups_small/iphone_7_plus_small.webp';
import iphone8_small from '../img/mockups_small/iphone_8_small.webp';
import iphone8Plus_small from '../img/mockups_small/iphone_8_plus_small.webp';
import iphoneX_small from '../img/mockups_small/iphone_x_small.webp';
import iphoneXR_small from '../img/mockups_small/iphone_xr_small.webp';
import iphoneXS_small from '../img/mockups_small/iphone_xs_small.webp';
import iphoneXSMax_small from '../img/mockups_small/iphone_xs_max_small.webp';
import iphone11_small from '../img/mockups_small/iphone_11_small.webp';
import iphone11Pro_small from '../img/mockups_small/iphone_11_pro_small.webp';
import iphone11ProMax_small from '../img/mockups_small/iphone_11_pro_max_small.webp';
import iphone12Mini_small from '../img/mockups_small/iphone_12_mini_small.webp';
import iphone12_small from '../img/mockups_small/iphone_12_small.webp';
import iphone12Pro_small from '../img/mockups_small/iphone_12_pro_small.webp';
import iphone12ProMax_small from '../img/mockups_small/iphone_12_pro_max_small.webp';
import iphone13Mini_small from '../img/mockups_small/iphone_13_mini_small.webp';
import iphone13_small from '../img/mockups_small/iphone_13_small.webp';
import iphone13Pro_small from '../img/mockups_small/iphone_13_pro_small.webp';
import iphone13ProMax_small from '../img/mockups_small/iphone_13_pro_max_small.webp';
import iphone14_small from '../img/mockups_small/iphone_14_small.webp';
import iphone14Plus_small from '../img/mockups_small/iphone_14_plus_small.webp';
import iphone14Pro_small from '../img/mockups_small/iphone_14_pro_small.webp';
import iphone14ProMax_small from '../img/mockups_small/iphone_14_pro_max_small.webp';
import iphone15_small from '../img/mockups_small/iphone_15_small.webp';
import iphone15Plus_small from '../img/mockups_small/iphone_15_plus_small.webp';
import iphone15Pro_small from '../img/mockups_small/iphone_15_pro_small.webp';
import iphone15ProMax_small from '../img/mockups_small/iphone_15_pro_max_small.webp';
import galaxyS10_small from '../img/mockups_small/galaxy_s10_small.webp';
import galaxyS10E_small from '../img/mockups_small/galaxy_s10_e_small.webp';
import galaxyS10Plus_small from '../img/mockups_small/galaxy_s10_plus_small.webp';
import galaxyS20_small from '../img/mockups_small/galaxy_s20_small.webp';
import galaxyS20Plus_small from '../img/mockups_small/galaxy_s20_plus_small.webp';
import galaxyS20Ultra_small from '../img/mockups_small/galaxy_s20_ultra_small.webp';
import galaxyS21_small from '../img/mockups_small/galaxy_s21_small.webp';
import galaxyS21Plus_small from '../img/mockups_small/galaxy_s21_plus_small.webp';
import galaxyS21Ultra_small from '../img/mockups_small/galaxy_s21_ultra_small.webp';
import galaxyS22_small from '../img/mockups_small/galaxy_s22_small.webp';
import galaxyS22Plus_small from '../img/mockups_small/galaxy_s22_plus_small.webp';
import galaxyS22Ultra_small from '../img/mockups_small/galaxy_s22_ultra_small.webp';
import galaxyS23_small from '../img/mockups_small/galaxy_s23_small.webp';
import galaxyS23Plus_small from '../img/mockups_small/galaxy_s23_plus_small.webp';
import galaxyS23Ultra_small from '../img/mockups_small/galaxy_s23_ultra_small.webp';

import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import ItemsContext from '../contexts/ItemsContext';
import UserInfoContext from '../contexts/UserInfoContext';

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const Background = styled.div`
  width: 100%;
  background: #fff9ef;
  padding-top: 20px;
  padding-bottom: 10rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
  };
`;

const RightContainer = styled.div`
  position: relative;
  width: 90%;
  max-width: 800px;
  margin-top: 20px;
  @media (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
    width: 88%;
    margin-top: 2rem;
  }
`;

const LeftContainer = styled.div`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const BarForTwoCircleButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const TwoCircleButtons = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
`;

const MoveButton = styled.button`
  border: none;
  border-radius: 18px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0px;
  background: #d5d4c3;
  box-sizing: border-box;
  color: #222;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`;

const MoveButtonText = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  font-size: 18px;
  color: #222;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 0rem;
  }
`;

const BouncyLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  padding: 0;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-family: 'NewKansas';
  font-size: 1.5rem;
  font-weight: bold;
  color: #222222;
  display: block;
  margin-bottom: .7rem;
`;

const LabelLight = styled.label`
  font-family: 'NewKansas';
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff9ef;
  display: block;
  margin-bottom: .7rem;
`

const OptionsDrawer = styled.div`
  height: auto;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background: #ffd899;
  border-radius: 26px;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const DoodlebugDisplay = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  gap: 10px;
  margin-bottom: 2rem;
`;

const SingleDoodlebug = styled.div`
  border: 4px solid ${({selected}) => (selected ? '#fe814d' : '#d5d4c3 ')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DoodlebugImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const NewDoodlebugCard = styled.div`
  width: 42px;
  height: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HelpfulText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  display: block;
  color: #5e5e5e;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  gap: 4px;
  align-items: center;
`;

const HelpfulTextNoMarginBottom = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  display: block;
  color: #5e5e5e;
  display: flex;
  justify-content: left;
  gap: 4px;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 470px) {
    flex-direction: column;
  }
`

const NewSelect1 = styled(Select)`
  box-sizing: border-box;
  width: 9rem;
  max-width: 9rem;
  margin-right: 0;
  color: #222;
  @media (max-width: 400px) {

  }
  border: 4px solid '#d5d4c3 ';
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NewSelect2 = styled(Select)`
  box-sizing: border-box;
  width: 11rem;
  max-width: 11rem;
  margin-right: 0;
  color: #222;
  @media (max-width: 400px) {

  }
  border: 4px solid '#d5d4c3 ';
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 400px) {
    margin-bottom: 12px;
  }
`

const ImageControlsButton = styled.button`
  border: none;
  border-radius: 30px;
  height: 3.5rem;
  max-height: 3.5rem;
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
  background: #f0f0f0;
  box-sizing: border-box;
  padding: 0;
  @media (max-width: 400px) {
    font=size: 14px;
    height: 3rem;
  }
`;

const ProductTitle = styled.label`
  font-family: 'NewKansas';
  font-size: 32px;
  font-weight: bold;
  color: #222222;
  display: block;
`;

const RatingDisplay = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`

const ReviewsLabel = styled.span`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #222;
  text-decoration: underline;
`;

const InfoTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 1.3rem;
  margin-top: 1.3rem;
`

const LilInfoTag = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  border-radius: 38px;
  background: ${props => props.color};
  gap: 10px;
  margin-bottom: .8rem;
  width: auto;
`;

const LilInfoText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #222;
`;

const CheckoutButton = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
  @media (max-width: 800px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CheckoutButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 28px;
  @media (max-width: 600px) {
    font-size: 24px;
    margin-bottom: 0rem;
  }
`;

const AddToCartButton = styled.button`
  border: none;
  height: 52px;
  padding: 10px 20px;
  background: #d5d4c3;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
  @media (max-width: 800px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const AddToCartButtonTextAndIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddToCartButtonText = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  font-size: 20px;
  color: #222;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const ColorSwatch = styled.div`
  border-radius: 50%;
  border: none;
  height: 32px;
  width: 32px;
  background: ${props => props.color};
  border: 1px solid ${props => props.color === '#FFFFFF' ? '#000000' : props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: linear-gradient(80deg, #c13584, #e1306c, #fd1d1d);
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #333;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const PopupContainer = styled(motion.div)`
  background: #fff9ef;
  padding: 2rem;
  border-radius: 10px;
  width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PopupTitle = styled.h2`
  font-family: 'NewKansas', sans-serif;
  font-size: 36px;
  text-align: center;
  margin-bottom: 1rem;
  color: #222;
`;

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  color: #222;
  margin-bottom: 3rem;
`;

const PopupButton = styled.button`
width: 300px;
border: none;
height: 64px;
padding: 10px 20px;
color: #fff9ef;
background: #fe814d;
border-radius: 18px;
font-weight: bold;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 1rem;
transform: scale(1);
margin-left: auto;
margin-right: auto;
&::after {
  content: '';
  position: absolute;
  border-radius: 68% / 5%;
  background-color: #fe814d;
  top: -1px;
  bottom: -1px;
  right: 13px;
  left: 13px;
  z-index: -1;
}
&:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
}
&:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
}
@media (max-width: 600px) {
  font-size: 14px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
`;


const OrderPhoneCase = ({ openCart }) => {
    const [selectedSize, setSelectedSize] = useState('iPhone 15');
    const [canvasWidth, setCanvasWidth] = useState(window.width > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400));
    const selectedSizeRef = useRef(selectedSize);
    const navigate = useNavigate();
    const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
    const { items, setItems } = useContext(ItemsContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const [selectedDoodlebug, setSelectedDoodlebug] = useState(savedDoodlebugs[0] ? savedDoodlebugs[0] : null);
    const [openMoveOptions, setOpenMoveOptions] = useState(false);
    
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    const iPhoneSizes = ["iPhone 7", "iPhone 7 Plus", "iPhone 8", "iPhone 8 Plus", "iPhone X", "iPhone XR", "iPhone XS", "iPhone XS Max", "iPhone 11", "iPhone 11 Pro", "iPhone 11 Pro Max", "iPhone SE (2020)", "iPhone 12 mini", "iPhone 12", "iPhone 12 Pro", "iPhone 12 Pro Max", "iPhone 13 mini", "iPhone 13", "iPhone 13 Pro", "iPhone 13 Pro Max", "iPhone 14", "iPhone 14 Plus", "iPhone 14 Pro", "iPhone 14 Pro Max", "iPhone 15", "iPhone 15 Plus", "iPhone 15 Pro", "iPhone 15 Pro Max"];
    const samsungSizes = ["Galaxy S10", "Galaxy S10+", "Galaxy S10e", "Galaxy S20", "Galaxy S20 Plus", "Galaxy S20 Ultra", "Galaxy S21", "Galaxy S21 Plus", "Galaxy S21 Ultra", "Galaxy S22", "Galaxy S22 Plus", "Galaxy S22 Ultra", "Galaxy S23", "Galaxy S23 Plus", "Galaxy S23 Ultra"];
    const [sizes, setSizes] = useState(iPhoneSizes);
    const makes = ['Apple', 'Samsung'];
    const [make, setMake] = useState('Apple');
    const [makeOptions, setMakeOptions] = useState([
      { value: 'Apple', label: 'Apple' },
      { value: 'Samsung', label: 'Samsung' }
    ]);
    const [sizeOptions, setSizeOptions] = useState([
      { value: 'iPhone 7', label: 'iPhone 7' },
      { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
      { value: 'iPhone 8', label: 'iPhone 8' },
      { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
      { value: 'iPhone X', label: 'iPhone X' },
      { value: 'iPhone XR', label: 'iPhone XR' },
      { value: 'iPhone XS', label: 'iPhone XS' },
      { value: 'iPhone XS Max', label: 'iPhone XS Max' },
      { value: 'iPhone 11', label: 'iPhone 11' },
      { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
      { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
      { value: 'iPhone SE (2020)', label: 'iPhone SE (2020)' },
      { value: 'iPhone 12 mini', label: 'iPhone 12 mini' },
      { value: 'iPhone 12', label: 'iPhone 12' },
      { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
      { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
      { value: 'iPhone 13 mini', label: 'iPhone 13 mini' },
      { value: 'iPhone 13', label: 'iPhone 13' },
      { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
      { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
      { value: 'iPhone 14', label: 'iPhone 14' },
      { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
      { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
      { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
      { value: 'iPhone 15', label: 'iPhone 15' },
      { value: 'iPhone 15 Plus', label: 'iPhone 15 Plus' },
      { value: 'iPhone 15 Pro', label: 'iPhone 15 Pro' },
      { value: 'iPhone 15 Pro Max', label: 'iPhone 15 Pro Max' }
    ]);
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [print, printStatus] = useImage(`${selectedDoodlebug}?t=${timestamp}`, 'anonymous');
    const [printX, setPrintX] = useState(0);
    const [printY, setPrintY] = useState(0);
    const [printWidth, setPrintWidth] = useState(0);
    const [printHeight, setPrintHeight] = useState(0);
    const [displayedPrintWidth, setDisplayedPrintWidth] = useState(0);
    const [displayedPrintHeight, setDisplayedPrintHeight] = useState(0);
    const printRef = useRef(null);
    const stageRef = useRef(null);
    const transformerRef = useRef(null);
    const [isTransformerVisible, setIsTransformerVisible] = useState(false);

    // map from selectedSize to imported images
    const caseImagePaths = window.innerWidth > 800 ? {
        'iPhone 15': iphone15,
        'iPhone 15 Plus': iphone15Plus,
        'iPhone 15 Pro': iphone15Pro,
        'iPhone 15 Pro Max': iphone15ProMax,
        'iPhone 14': iphone14,
        'iPhone 14 Plus': iphone14Plus,
        'iPhone 14 Pro': iphone14Pro,
        'iPhone 14 Pro Max': iphone14ProMax,
        'iPhone 13': iphone13,
        'iPhone 13 mini': iphone13Mini,
        'iPhone 13 Pro': iphone13Pro,
        'iPhone 13 Pro Max': iphone13ProMax,
        'iPhone 12': iphone12,
        'iPhone 12 mini': iphone12Mini,
        'iPhone 12 Pro': iphone12Pro,
        'iPhone 12 Pro Max': iphone12ProMax,
        'iPhone 11': iphone11,
        'iPhone 11 Pro': iphone11Pro,
        'iPhone 11 Pro Max': iphone11ProMax,
        'iPhone X': iphoneX,
        'iPhone XS': iphoneXS,
        'iPhone XS Max': iphoneXSMax,
        'iPhone XR': iphoneXR,
        'iPhone 7': iphone7,
        'iPhone 8': iphone8,
        'iPhone 7 Plus': iphone7Plus,
        'iPhone 8 Plus': iphone8Plus,
        'Galaxy S10': galaxyS10,
        'Galaxy S10e': galaxyS10E,
        'Galaxy S10+': galaxyS10Plus,
        'Galaxy S20': galaxyS20,
        'Galaxy S20 Plus': galaxyS20Plus,
        'Galaxy S20 Ultra': galaxyS20Ultra,
        'Galaxy S21': galaxyS21,
        'Galaxy S21 Plus': galaxyS21Plus,
        'Galaxy S21 Ultra': galaxyS21Ultra,
        'Galaxy S22': galaxyS22,
        'Galaxy S22 Plus': galaxyS22Plus,
        'Galaxy S22 Ultra': galaxyS22Ultra,
        'Galaxy S23': galaxyS23,
        'Galaxy S23 Plus': galaxyS23Plus,
        'Galaxy S23 Ultra': galaxyS23Ultra
    } : {
        'iPhone 15': iphone15_small,
        'iPhone 15 Plus': iphone15Plus_small,
        'iPhone 15 Pro': iphone15Pro_small,
        'iPhone 15 Pro Max': iphone15ProMax_small,
        'iPhone 14': iphone14_small,
        'iPhone 14 Plus': iphone14Plus_small,
        'iPhone 14 Pro': iphone14Pro_small,
        'iPhone 14 Pro Max': iphone14ProMax_small,
        'iPhone 13': iphone13_small,
        'iPhone 13 mini': iphone13Mini_small,
        'iPhone 13 Pro': iphone13Pro_small,
        'iPhone 13 Pro Max': iphone13ProMax_small,
        'iPhone 12': iphone12_small,
        'iPhone 12 mini': iphone12Mini_small,
        'iPhone 12 Pro': iphone12Pro_small,
        'iPhone 12 Pro Max': iphone12ProMax_small,
        'iPhone 11': iphone11_small,
        'iPhone 11 Pro': iphone11Pro_small,
        'iPhone 11 Pro Max': iphone11ProMax_small,
        'iPhone X': iphoneX_small,
        'iPhone XS': iphoneXS_small,
        'iPhone XS Max': iphoneXSMax_small,
        'iPhone XR': iphoneXR_small,
        'iPhone 7': iphone7_small,
        'iPhone 8': iphone8_small,
        'iPhone 7 Plus': iphone7Plus_small,
        'iPhone 8 Plus': iphone8Plus_small,
        'Galaxy S10': galaxyS10_small,
        'Galaxy S10e': galaxyS10E_small,
        'Galaxy S10+': galaxyS10Plus_small,
        'Galaxy S20': galaxyS20_small,
        'Galaxy S20 Plus': galaxyS20Plus_small,
        'Galaxy S20 Ultra': galaxyS20Ultra_small,
        'Galaxy S21': galaxyS21_small,
        'Galaxy S21 Plus': galaxyS21Plus_small,
        'Galaxy S21 Ultra': galaxyS21Ultra_small,
        'Galaxy S22': galaxyS22_small,
        'Galaxy S22 Plus': galaxyS22Plus_small,
        'Galaxy S22 Ultra': galaxyS22Ultra_small,
        'Galaxy S23': galaxyS23_small,
        'Galaxy S23 Plus': galaxyS23Plus_small,
        'Galaxy S23 Ultra': galaxyS23Ultra_small
    };
    const [caseImagePath, setCaseImagePath] = useState(caseImagePaths[selectedSize]);

    useEffect(() => {
      document.title = 'Doodlebug | Order Phone Case';
    }, []);

    // reset case image path when selected size changes
    useEffect(() => {
        setCaseImagePath(caseImagePaths[selectedSize]);
    }, [selectedSize]);
    const [caseImage, caseImageStatus] = useImage(caseImagePath);

    useEffect(() => {
      if (!selectedDoodlebug) {
        setSelectedDoodlebug(savedDoodlebugs[0])
      }
    }, [savedDoodlebugs]);

    useEffect(() => {
      if(print) {
        //this should also probably depend on canvasWidth and canvasHeight...
          const initialHeight = 180;
          const widthHeightRatio = print.width / print.height;
          setPrintHeight(initialHeight);
          setPrintWidth(initialHeight * widthHeightRatio);
          setDisplayedPrintHeight(initialHeight);
          setDisplayedPrintWidth(initialHeight * widthHeightRatio);
          setPrintX(((canvasWidth*(1748/2480))-(initialHeight*widthHeightRatio))/2);
          setPrintY(140);
      }
    }, [print]);

    const originalCanvasStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0,
        'height': canvasWidth,
        'border': '4px solid #d5d4c3',
        'borderRadius': '15px',
        'position': 'relative',
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'marginTop': '20px',
        'objectFit': 'contain',
        'alignItems': 'center',
        'justifyContent': 'center',
        'height': canvasWidth,
        'border': '4px solid #d5d4c3',
        'borderRadius': '15px'
    };
    const [canvasStyling, setCanvasStyling] = useState(originalCanvasStyling);

    const originalSkeletonStyling = window.innerWidth > 800 ? {
        'top': '60',
        'left': '0',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'objectFit': 'contain',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'zIndex': 0,
        'border': '4px solid #fff9ef',
    } : {
        'position': 'relative',
        'marginRight': 'auto',
        'marginLeft': 'auto',
        'objectFit': 'contain',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'border': '4px solid #fff9ef',
    }
    const [skeletonStyling, setSkeletonStyling] = useState(originalSkeletonStyling);

    const selectStyles = {
      control: (base) => ({
        ...base,
        border: '4px solid #d5d4c3',
        borderRadius: '8px',
        boxShadow: 'none',
        boxSizing: 'border-box',
        height: '50px',
        appearance: 'none',
        backgroundColor: '#fff',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '18px'
      }),
      option: (base) => ({
        ...base,
        color: '#222',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px'
      }),
      placeholder: (base) => ({
        ...base,
        color: '#222',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px',
      })
    };

    // scroll to the top
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // attaches the transformer to the print image for resizing
    useEffect(() => {
        if (printRef.current && isTransformerVisible) {
          transformerRef.current.nodes([printRef.current]);
          transformerRef.current.getLayer().batchDraw();
        }
    });

    // this updates the canvasWidth and showLeftContainer state variables upon resize, and resizes the skeleton
    useEffect(() => {
        const handleResize = () => {
            if (print && printRef.current) {
                const node = printRef.current;
                const newCanvasHeight = window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400);
                const newCanvasWidth = newCanvasHeight * (1748/2480); // because our case images, and therefore our canvas too, are of dimensions 1748*2480
                const newPrintWidth = (newCanvasWidth / canvasWidth) * node.width();
                const newPrintHeight = newPrintWidth * (1024/896) // because all our images right now are generated in dimensions 896*1024
                const newPrintX = (canvasWidth / node.x()) * newCanvasWidth;
                const newPrintY = ((canvasWidth * (2480/1748)) / node.y()) * newCanvasHeight;
                const newDisplayedWidth = (newCanvasWidth / canvasWidth) * displayedPrintWidth;
                const newDisplayedHieght = newDisplayedWidth * (1024/896);
                setCanvasWidth(newCanvasHeight); // just because -- I'll need to fix the nomenclature later, but his is how it is for now unfortunately
                setPrintWidth(newPrintWidth);
                setPrintHeight(newPrintHeight);
                setPrintX(newPrintX);
                setPrintY(newPrintY);
                setDisplayedPrintWidth(newDisplayedWidth);
                setDisplayedPrintHeight(newDisplayedHieght);
                // After state updates, manually update the node to ensure consistency
                setTimeout(() => {
                    node.width(newPrintWidth);
                    node.height(newPrintHeight);
                    node.x(newPrintX);
                    node.y(newPrintY);
                }, 0);
                setSkeletonStyling(window.innerWidth > 800 ? {
                    'top': '60',
                    'left': '0',
                    'marginLeft': 'auto',
                    'marginRight': 'auto',
                    'objectFit': 'contain',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 0,
                    'border': '4px solid #fff9ef',
                } : {
                    'position': 'relative',
                    'marginRight': 'auto',
                    'marginLeft': 'auto',
                    'objectFit': 'contain',
                    'display': 'flex',
                    'alignItems': 'center',
                    'justifyContent': 'center',
                    'border': '4px solid #fff9ef',
                });
                setCanvasStyling(window.innerWidth > 800 ? {
                    'top': '60',
                    'left': '0',
                    'marginLeft': 'auto',
                    'marginRight': 'auto',
                    'marginTop': '20px',
                    'objectFit': 'contain',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 0,
                    'height': window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400),
                    'border': '4px solid #d5d4c3',
                    'borderRadius': '15px',
                    'position': 'relative',
                } : {
                    'position': 'relative',
                    'marginRight': 'auto',
                    'marginLeft': 'auto',
                    'marginTop': '20px',
                    'objectFit': 'contain',
                    'alignItems': 'center',
                    'justifyContent': 'center',
                    'height': window.innerWidth > 800 ? Math.min(document.documentElement.clientWidth/2, window.innerHeight-180) : Math.min(document.documentElement.clientWidth, 400),
                    'border': '4px solid #d5d4c3',
                    'borderRadius': '15px'
                });
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [canvasWidth]);

    // this listens for visibility changes, so that if the user tabs out and then returns to the page, the stage is redrawn.
    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible' && stageRef.current) {
            stageRef.current.batchDraw();
          }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    
    // updates the selectedSizeRef when selectedSize changes, and resets the doodlebug positioning
    useEffect(() => {
      selectedSizeRef.current = selectedSize;
      reset();
    }, [selectedSize]);

    const handleMakeChange = (selectedOption) => {
      if(selectedOption.value === make) {
        return;
      }
      setMake(selectedOption.value);
      if (selectedOption.value === 'Apple') {
        setSizes(iPhoneSizes);
        setSizeOptions([
          { value: 'iPhone 7', label: 'iPhone 7' },
          { value: 'iPhone 7 Plus', label: 'iPhone 7 Plus' },
          { value: 'iPhone 8', label: 'iPhone 8' },
          { value: 'iPhone 8 Plus', label: 'iPhone 8 Plus' },
          { value: 'iPhone X', label: 'iPhone X' },
          { value: 'iPhone XR', label: 'iPhone XR' },
          { value: 'iPhone XS', label: 'iPhone XS' },
          { value: 'iPhone XS Max', label: 'iPhone XS Max' },
          { value: 'iPhone 11', label: 'iPhone 11' },
          { value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
          { value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
          { value: 'iPhone SE (2020)', label: 'iPhone SE (2020)' },
          { value: 'iPhone 12 mini', label: 'iPhone 12 mini' },
          { value: 'iPhone 12', label: 'iPhone 12' },
          { value: 'iPhone 12 Pro', label: 'iPhone 12 Pro' },
          { value: 'iPhone 12 Pro Max', label: 'iPhone 12 Pro Max' },
          { value: 'iPhone 13 mini', label: 'iPhone 13 mini' },
          { value: 'iPhone 13', label: 'iPhone 13' },
          { value: 'iPhone 13 Pro', label: 'iPhone 13 Pro' },
          { value: 'iPhone 13 Pro Max', label: 'iPhone 13 Pro Max' },
          { value: 'iPhone 14', label: 'iPhone 14' },
          { value: 'iPhone 14 Plus', label: 'iPhone 14 Plus' },
          { value: 'iPhone 14 Pro', label: 'iPhone 14 Pro' },
          { value: 'iPhone 14 Pro Max', label: 'iPhone 14 Pro Max' },
          { value: 'iPhone 15', label: 'iPhone 15' },
          { value: 'iPhone 15 Plus', label: 'iPhone 15 Plus' },
          { value: 'iPhone 15 Pro', label: 'iPhone 15 Pro' },
          { value: 'iPhone 15 Pro Max', label: 'iPhone 15 Pro Max' }
        ]);
        setSelectedSize('iPhone 15');
      } else if (selectedOption.value === 'Samsung') {
        setSizes(samsungSizes);
        setSizeOptions([
          { value: 'Galaxy S10', label: 'Galaxy S10' },
          { value: 'Galaxy S10+', label: 'Galaxy S10+' },
          { value: 'Galaxy S10e', label: 'Galaxy S10e' },
          { value: 'Galaxy S20', label: 'Galaxy S20' },
          { value: 'Galaxy S20 Plus', label: 'Galaxy S20 Plus' },
          { value: 'Galaxy S20 Ultra', label: 'Galaxy S20 Ultra' },
          { value: 'Galaxy S21', label: 'Galaxy S21' },
          { value: 'Galaxy S21 Plus', label: 'Galaxy S21 Plus' },
          { value: 'Galaxy S21 Ultra', label: 'Galaxy S21 Ultra' },
          { value: 'Galaxy S22', label: 'Galaxy S22' },
          { value: 'Galaxy S22 Plus', label: 'Galaxy S22 Plus' },
          { value: 'Galaxy S22 Ultra', label: 'Galaxy S22 Ultra' },
          { value: 'Galaxy S23', label: 'Galaxy S23' },
          { value: 'Galaxy S23 Plus', label: 'Galaxy S23 Plus' },
          { value: 'Galaxy S23 Ultra', label: 'Galaxy S23 Ultra' },
        ]);
        setSelectedSize('Galaxy S23');
      }
    }

    // this changes the phone model
    const handleSizeChange = async (selectedOption) => {
      setSelectedSize(selectedOption.value);
    }

    // to reset the image back to its default placement
    const reset = () => {
      if (print && printRef.current) {
        // initialHeight should be proportional to the canvasheight;
        const initialHeight = 180;
        const widthHeightRatio = print.width / print.height;
        const newWidth = initialHeight * widthHeightRatio;
        setPrintHeight(initialHeight);
        setPrintWidth(newWidth);
        setDisplayedPrintHeight(initialHeight);
        setDisplayedPrintWidth(initialHeight * widthHeightRatio);
        setPrintX(((canvasWidth * (1748 / 2480)) - newWidth) / 2);
        setPrintY(140);
    
        const node = printRef.current;
        node.setAttr('width', newWidth);
        node.setAttr('height', initialHeight);
        node.setAttr('x', ((canvasWidth * (1748 / 2480)) - newWidth) / 2);
        node.setAttr('y', 140);
        node.setAttr('scaleX', 1);
        node.setAttr('scaleY', 1);
        node.setAttr('rotation', 0);
    

        if(transformerRef.current) {
          transformerRef.current.forceUpdate();
        }
        node.getLayer().batchDraw();
      }
    };

    const toggleTransformer = () => {
      isTransformerVisible ? setOpenMoveOptions(false) : setOpenMoveOptions(true);
      setIsTransformerVisible(!isTransformerVisible);
    };

    // this function is responsible for the snap-to-center mechanic
    const printDragBoundFunc = (pos) => {
      const canvasCenter = canvasWidth * (1748 / 2480) / 2;
      const imageHorizontalCenter = displayedPrintWidth / 2;
      const snapDistance = 6;
      const distance = Math.abs(pos.x + imageHorizontalCenter - canvasCenter);

      if (distance < snapDistance) {
        return {
          x: canvasCenter - imageHorizontalCenter,
          y: pos.y
        };
      }

      return pos;
    };

    // updates printHeight and printWidth upon resize using the border/boxes supplied by the "Transformer"
    const onTransformEnd = () => {
      const node = printRef.current;
      setDisplayedPrintWidth(node.width() * node.scaleX());
      setDisplayedPrintHeight(node.height() * node.scaleY());
    };

    const moveThePrint = (event) => {
      if(isTransformerVisible) {
        setPrintX(event.target.x());
        setPrintY(event.target.y());
      }
    };

    const selectDoodlebug = (url) => {
        setSelectedDoodlebug(url);
        reset();
    };

    const handleCreateNewDoodlebug = () => {
      navigate('/new-doodlebug');
    };

    const generateUUID = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    const addToCart = async () => {
      const stage = stageRef.current;
      const transformer = stage.findOne('Transformer');
      if(transformer) {
        transformer.hide();
      };
      setIsTransformerVisible(false);
      setOpenMoveOptions(false);
      const variantId = (
        selectedSize === "iPhone 15 Pro Max" ? '15PM-MS' :
        selectedSize === "iPhone 15 Pro" ? '15P-MS' :
        selectedSize === "iPhone 15 Plus" ? '15PL-MS' :
        selectedSize === "iPhone 15" ? '15-MS' :
        selectedSize === "iPhone 14 Pro Max" ? '14PM-MS' :
        selectedSize === "iPhone 14 Pro" ? '14P-MS' :
        selectedSize === "iPhone 14 Plus" ? '14PL-MS' :
        selectedSize === "iPhone 14" ? '14-MS' :
        selectedSize === "iPhone 13 Pro Max" ? '13PM-MS' :
        selectedSize === "iPhone 13 Pro" ? '13P-MS' :
        selectedSize === "iPhone 13" ? '13-MS' :
        selectedSize === "iPhone 13 mini" ? '13M-MS' :
        selectedSize === "iPhone 12 Pro Max" ? '12PMT' :
        selectedSize === "iPhone 12 Pro" ? '12PT' :
        selectedSize === "iPhone 12" ? '12T' :
        selectedSize === "iPhone 12 mini" ? '12MT' :
        selectedSize === "iPhone SE (2020)" ? 'SE' :
        selectedSize === "iPhone 11 Pro Max" ? '11PMT' :
        selectedSize === "iPhone 11 Pro" ? '11PT' :
        selectedSize === "iPhone 11" ?  '11T' :
        selectedSize === "iPhone XR" ? 'XRT' :
        selectedSize === "iPhone XS Max" ? 'XSMT' :
        selectedSize === "iPhone X" ? 'XT' :
        selectedSize === "iPhone XS" ? 'XST' :
        selectedSize === "iPhone 7 Plus" ? '7PT' :
        selectedSize === "iPhone 8 Plus" ? '8PT' :
        selectedSize === "iPhone 7" ? '7T' :
        selectedSize === "iPhone 8" ? '8T' :
        selectedSize === "Galaxy S10" ? 'S10T' :
        selectedSize === "Galaxy S10+" ? 'S10PT' :
        selectedSize === "Galaxy S10e" ? 'S10ET' :
        selectedSize === "Galaxy S20" ? 'S20T' :
        selectedSize === "Galaxy S20 Plus" ? 'S20PT' :
        selectedSize === "Galaxy S20 Ultra" ? 'S20UT' :
        selectedSize === "Galaxy S21" ? 'S21T' :
        selectedSize === "Galaxy S21 Plus" ? 'S21PT' :
        selectedSize === "Galaxy S21 Ultra" ? 'S21UT' :
        selectedSize === "Galaxy S22" ? 'S22T' :
        selectedSize === "Galaxy S22 Plus" ? 'S22PT' :
        selectedSize === "Galaxy S22 Ultra" ? 'S22UT' :
        selectedSize === "Galaxy S23" ? 'S23T' :
        selectedSize === "Galaxy S23 Plus" ? 'S23PT' :
        selectedSize === "Galaxy S23 Ultra" ? 'S23UT' :
        undefined
      );
      const mockupUrl = stageRef.current.toDataURL({ mimeType: 'image/webp', quality: 0.92 });
      const key = 'phone_case' + generateUUID();
      const randomColorOptions = ['#ffd899','#d5d8ec','#fcc7c1','#c7deba'];
      const randomIndex = Math.floor(Math.random() * randomColorOptions.length);

      // phone cases have positionable prints, so we will give them unique keys instead of checking for a matching product in the existing items
      // if later we decide to do a check, it needs to check all of the canvas/print dimensions, because otherwise the case is not the same
      const newItem = {
        type: 'phone-case',
        size: selectedSize,
        variantId: variantId,
        imageUrl: selectedDoodlebug,
        quantity: 1,
        dimensions: {
          canvasWidth: canvasWidth,
          printWidth: printWidth,
          printHeight: printHeight,
          displayedPrintWidth: displayedPrintWidth,
          displayedPrintHeight: displayedPrintHeight,
          rotation: printRef.current.rotation(),
          printX: printX,
          printY: printY
        },
        key: key,
        cartColor: randomColorOptions[randomIndex]
      }
      setItems(prevItems => [...prevItems, newItem]);
      localStorage.setItem(key, mockupUrl);
      openCart();
    };

    const buyNow = () => {

    };


    return (
        <Background>
          {showErrorPopup && (
            <ErrorPopup><PopupText>Generation failed. Our servers are experiencing high demand--please try again.</PopupText></ErrorPopup>
          )}
          {(savedDoodlebugs.length < 1 && userInfo.status !== "getting") && (
            <Overlay>
                <PopupContainer>
                    <PopupTitle>You have no doodlebugs</PopupTitle>
                    <PopupText>You can't order a doodlebug that doesn't exist. Create a doodlebug first! If you already have doodlebugs connected to an account, sign in to access them.</PopupText>
                    <PopupButton onClick={handleCreateNewDoodlebug}><CheckoutButtonText>Create Doodlebug</CheckoutButtonText></PopupButton>
                </PopupContainer>
            </Overlay>
          )}
            <Container>
                <LeftContainer>
                  {(caseImageStatus === 'loaded' && printStatus === 'loaded' && printWidth && printHeight && savedDoodlebugs.length > 0) ? (
                    <div style={canvasStyling}>
                        <Stage width={canvasWidth*(1748/2480)} height={canvasWidth} style={{borderRadius: '11px', overflow: 'hidden'}} ref={stageRef}>
                            <Layer>
                                <Rect
                                  x={0}
                                  y={0}
                                  width={canvasWidth*(1748/2480)}
                                  height={canvasWidth}
                                  fill={'#fff'}
                                />
                                <Image image={print} x={printX} y={printY} width={printWidth} height={printHeight} draggable={isTransformerVisible} onDragMove={moveThePrint} ref={printRef} dragBoundFunc={printDragBoundFunc}/>
                                <Image image={caseImage} x={0} y={0} width={canvasWidth*(1748/2480)} height={canvasWidth} listening={false}/>
                                {isTransformerVisible && (
                                  <Transformer
                                    ref={transformerRef}
                                    rotationSnaps={[0, 90, 180, 270]}
                                    rotationSnapTolerance={5}
                                    boundBoxFunc={(oldBox, newBox) => {
                                      return newBox;
                                    }}
                                    onTransformEnd={onTransformEnd}
                                  />
                                )}
                            </Layer>
                        </Stage>
                    </div>
                  ) : (savedDoodlebugs.length < 1) || (caseImageStatus === 'loaded' && printStatus !== 'loaded' && printWidth && printHeight) ? (
                    <div style={canvasStyling}>
                        <Stage width={canvasWidth*(1748/2480)} height={canvasWidth} style={{borderRadius: '11px', overflow: 'hidden'}} ref={stageRef}>
                            <Layer>
                                <Rect
                                  x={0}
                                  y={0}
                                  width={canvasWidth*(1748/2480)}
                                  height={canvasWidth}
                                  fill={'#fff'}
                                />
                                <Image image={caseImage} x={0} y={0} width={canvasWidth*(1748/2480)} height={canvasWidth} listening={false}/>
                            </Layer>
                        </Stage>
                        <BouncyLoaderContainer>
                            <BouncyLoader />
                        </BouncyLoaderContainer>
                    </div>
                  ) : (
                    <div style={canvasStyling}>
                        <Stage width={canvasWidth*(1748/2480)} height={canvasWidth} style={{borderRadius: '11px', overflow: 'hidden'}} ref={stageRef}>
                            <Layer>
                                <Rect
                                  x={0}
                                  y={0}
                                  width={canvasWidth*(1748/2480)}
                                  height={canvasWidth}
                                  fill={'#fff'}
                                />
                            </Layer>
                        </Stage>
                        <BouncyLoaderContainer>
                            <BouncyLoader />
                        </BouncyLoaderContainer>
                    </div>
                  )}
                  <BarForTwoCircleButtons>
                    {openMoveOptions ? (
                      <motion.div>
                        <TwoCircleButtons>
                          <MoveButton onClick={reset}>
                              <ArrowCounterClockwise size={24} color="#222" />
                              <MoveButtonText>Reset</MoveButtonText>
                          </MoveButton>
                          <MoveButton valid={true} onClick={toggleTransformer}>
                              <Check size={24} color="#222" />
                              <MoveButtonText>Confirm</MoveButtonText>
                          </MoveButton>
                        </TwoCircleButtons>
                      </motion.div>
                    ) : (
                      <motion.div>
                        <MoveButton onClick={toggleTransformer}><ArrowsOutCardinal size={24} color="#222" /><MoveButtonText>Move</MoveButtonText></MoveButton>
                      </motion.div>
                    )}
                  </BarForTwoCircleButtons>
                </LeftContainer>
                <RightContainer>
                  <Wrapper>
                      <OptionsDrawer>
                          <Label>Choose Doodlebug</Label>
                          <DoodlebugDisplay>
                              {savedDoodlebugs.map((url) => (
                                <motion.div layout>
                                  <SingleDoodlebug key={url} onClick={() => selectDoodlebug(url)} selected={selectedDoodlebug === url}>
                                    <DoodlebugImage src={url} alt="Doodlebug" />
                                  </SingleDoodlebug>
                                </motion.div>
                              ))}
                              <motion.div layout>
                                  <SingleDoodlebug onClick={handleCreateNewDoodlebug}>
                                      <NewDoodlebugCard>
                                          <Plus size={20} />
                                      </NewDoodlebugCard>
                                  </SingleDoodlebug>
                              </motion.div>
                          </DoodlebugDisplay>
                          <Label>Phone Type</Label>
                          <SelectContainer>
                              <NewSelect1
                                options={makeOptions}
                                styles={selectStyles}
                                placeholder={make}
                                value={null}
                                isSearchable={false}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                onChange={handleMakeChange}
                              />
                              <NewSelect2
                                options={sizeOptions}
                                styles={selectStyles}
                                placeholder={selectedSize}
                                value={null}
                                isSearchable={false}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                onChange={handleSizeChange}
                              />
                            </SelectContainer>
                      </OptionsDrawer>
                    <ProductTitle>Doodlebug Phone Case</ProductTitle>
                    <RatingDisplay>
                        <Rating
                          emptySymbol={<Star size={20} color="#fdca40" />}
                          fullSymbol={<Star size={20} color="#fdca40" weight="fill" />}
                          fractions={2}
                          readonly
                          initialRating={4.7}
                          style={{ display: "block"}}
                        />
                        <ReviewsLabel>show {2} reviews</ReviewsLabel>
                    </RatingDisplay>
                    <InfoTagsContainer>
                        <LilInfoTag color='#c7deba'>
                            <CheckCircle size={24} color="#222222" />
                            <LilInfoText>Works with MagSafe (iPhones 12-15)</LilInfoText>
                        </LilInfoTag>
                        <LilInfoTag color='#fcc7c1'>
                            <Truck size={24} color="#222" />
                            <LilInfoText>FREE shipping!</LilInfoText>
                        </LilInfoTag>
                        <LilInfoTag color='#d5d8ec'>
                            <Timer size={24} color="#222" />
                            <LilInfoText>Arrives in 5-7 days</LilInfoText>
                        </LilInfoTag>
                    </InfoTagsContainer>
                    <CheckoutButton><CheckoutButtonText>Buy Now: $46</CheckoutButtonText></CheckoutButton>
                    <AddToCartButton onClick={addToCart}><AddToCartButtonTextAndIconContainer><ShoppingCartSimple size={24} color="#222" weight="bold"/><AddToCartButtonText>Add to Cart</AddToCartButtonText></AddToCartButtonTextAndIconContainer></AddToCartButton>
                  </Wrapper>
                </RightContainer>
            </Container>
        </Background>
    );
};

export default OrderPhoneCase;
