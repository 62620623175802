import CheckoutSuccess from "./pages/CheckoutSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AddReview from "./pages/AddReview";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Customize from "./pages/Customize";
import CustomizeFromUpload from "./pages/CustomizeFromUpload";
import OrderPhoneCase from "./pages/OrderPhoneCase";
import SavedImages from "./pages/SavedImages";
import SelectSavedImages from "./pages/SelectSavedImages";
import John316 from "./pages/John316";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
import GetUserState from "./components/GetUserState";
import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ImageContext from "./contexts/ImageContext";
import PromptContext from "./contexts/PromptContext";
import SavedImagesContext from "./contexts/SavedImagesContext";
import SavedDoodlebugsContext from "./contexts/SavedDoodlebugsContext";
import ItemsContext from "./contexts/ItemsContext";
import LastPageContext from "./contexts/LastPageContext";
import CaseImagesContext from "./contexts/CaseImagesContext";
import PrintContext from "./contexts/PrintContext";
import SelectSavedIntentContext from "./contexts/SelectSavedIntentContext";
import RecentCaseContext from "./contexts/RecentCaseContext";
import PngSequenceContext from "./contexts/PngSequenceContext";
import UserUploadContext from "./contexts/UserUploadContext";
import UserInfoContext from "./contexts/UserInfoContext";
import CaptionContext from "./contexts/CaptionContext";
import '@fortawesome/fontawesome-svg-core/styles.css';
import Cookies from 'js-cookie';
import { motion, LayoutGroup } from 'framer-motion';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const AllContainer = styled.div`
  ${props => props.isScrollDisabled && `
  overflow: hidden;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
  `};
  background: #fff9ef;
`;

const MainContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 30px);
  overflow-y: hidden;
  overflow-x: hidden;
`;

const GrowContainer = styled(motion.div)`
  flex: 1;
  background: #fff9ef;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;


const App = () => {
  async function initializeCSRF() {
    const CSRF_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/get-csrf-token' : 'http://localhost:3001/get-csrf-token';
    try {
      const response = await fetch(CSRF_URL, {
        credentials: 'include'
      });
      const data = await response.json();
      window.csrfToken = data.csrfToken;
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
    }
  };

  useEffect(() => {
    initializeCSRF();
  }, []);

  const [imageUrl, setImageUrl] = React.useState(null);
  const [prompt, setPrompt] = React.useState('');
  const [savedImages, setSavedImages] = React.useState([]);
  const [savedDoodlebugs, setSavedDoodlebugs] = React.useState([]);
  const [items, setItems] = React.useState(["getting"]);
  const [lastPage, setLastPage] = React.useState('prompt');
  const [selectSavedIntent, setSelectSavedIntent] = React.useState(''); // not using this, but it is still referenced in the code somewhere--no longer necessary
  const [caseImages, setCaseImages] = React.useState(null);
  const [print, setPrint] = React.useState(null);
  const [recentCase, setRecentCase] = React.useState(null);
  const [pngSequence, setPngSequence] = React.useState(null);
  const [userUpload, setUserUpload] = React.useState(null);
  const [caption, setCaption] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState({status: "getting"});
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const openCart = () => {
    setIsCartOpen(true);
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  useEffect(() => {
    // console.log('Doodlebug.me: We certify that this work was completed with all diligence and faithfulness in the service of God, the Father of all lights, with great love and profound admiration for the remarkable human family.')
  }, []);

  useEffect(() => {
    if (!imageUrl && Cookies.get('imageUrl')) {
      setImageUrl(Cookies.get('imageUrl'));
    }
    if (items.length === 0 && Cookies.get('items')) {
      setItems(JSON.parse(Cookies.get('items')));
    }
    if (savedImages.length === 0 && Cookies.get('savedImages')) {
      setSavedImages(JSON.parse(Cookies.get('savedImages')));
    }
  }, []);
  
  // keep the server cart updated
  const updateServerCart = useCallback(
    debounce(async (newCart) => {
      const CART_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/update-cart' : 'http://localhost:3001/update-cart';

      try {
        const response = await fetch(CART_URL, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': window.csrfToken
          },
          body: JSON.stringify({ items: newCart }),
        });

        if (!response.ok) {
          throw new Error('Failed to update cart on server');
        }
      } catch (error) {
        console.error('Error updating cart on server:', error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if(items[0] !== "getting") {
      updateServerCart(items);
    }
  }, [items, updateServerCart]);

  return (
    <ImageContext.Provider value = {{ imageUrl, setImageUrl}}>
      <PromptContext.Provider value = {{ prompt, setPrompt }}>
        <SavedImagesContext.Provider value = {{ savedImages, setSavedImages }}>
          <SavedDoodlebugsContext.Provider value = {{ savedDoodlebugs, setSavedDoodlebugs }}>
            <ItemsContext.Provider value = {{ items, setItems }}>
              <LastPageContext.Provider value = {{ lastPage, setLastPage }}>
                <SelectSavedIntentContext.Provider value = {{ selectSavedIntent, setSelectSavedIntent }}>
                  <CaseImagesContext.Provider value = {{ caseImages, setCaseImages }}>
                    <PrintContext.Provider value = {{ print, setPrint }}>
                      <RecentCaseContext.Provider value = {{ recentCase, setRecentCase }}>
                        <PngSequenceContext.Provider value = {{ pngSequence, setPngSequence }}>
                          <UserUploadContext.Provider value = {{ userUpload, setUserUpload }}>
                            <CaptionContext.Provider value = {{ caption, setCaption }}>
                              <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
                                <AllContainer style={{background: '#fff9ef'}} isScrollDisabled={isCartOpen}>
                                  <GetUserState />
                                  <Router>
                                    <Navbar openCart={openCart}/>
                                      <LayoutGroup>
                                        <MainContainer layout>
                                          <GrowContainer layout>
                                            <motion.div layout>
                                              <Routes>
                                                <Route path="/checkoutsuccess" element={<CheckoutSuccess/>}/>
                                                <Route path="privacy" element={<PrivacyPolicy/>}/>
                                                <Route path="addreview" element={<AddReview/>}/>
                                                <Route path="contact" element={<Contact/>}/>
                                                <Route path="terms" element={<Terms/>}/>
                                                <Route path="faq" element={<FAQ/>}/>
                                                <Route path="about" element={<About/>}/>
                                                <Route path="partners" element={<Partners/>}/>
                                                <Route path="customize" element={<Customize/>}/>
                                                <Route path="new-doodlebug" element={<Customize/>}/>
                                                <Route path="customize-from-upload" element={<CustomizeFromUpload/>}/>
                                                <Route path="favorites" element={<SavedImages/>}/>
                                                <Route path="selectfavorites" element={<SelectSavedImages/>}/>
                                                <Route path="order/phone-case" element={<OrderPhoneCase openCart={openCart}/>}/>
                                                <Route path="john316" element={<John316/>}/>
                                                <Route path="/" element={userInfo.status === "authenticated" ? <Home /> : <Landing />}/>
                                              </Routes>
                                            </motion.div>
                                          </GrowContainer>
                                          <motion.div layout>
                                            <Footer />
                                          </motion.div>
                                        </MainContainer>
                                      </LayoutGroup>
                                    <Cart isOpen={isCartOpen} onClose={closeCart} />
                                  </Router>
                                </AllContainer>
                              </UserInfoContext.Provider>
                            </CaptionContext.Provider>
                          </UserUploadContext.Provider>
                        </PngSequenceContext.Provider>
                      </RecentCaseContext.Provider>
                    </PrintContext.Provider>
                  </CaseImagesContext.Provider>
                </SelectSavedIntentContext.Provider>
              </LastPageContext.Provider>
            </ItemsContext.Provider>
          </SavedDoodlebugsContext.Provider>
        </SavedImagesContext.Provider>
      </PromptContext.Provider>
    </ImageContext.Provider>
  );
};

export default App;